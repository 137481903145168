import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"
import { I18nextContext } from "gatsby-plugin-react-i18next"

const timeout = 500
const getTransitionStyles = {
  entering: {
    position: `absolute` as const,
    opacity: 0,
    transform: `translateX(-100px)`,
  },
  entered: {
    transition: `all ${timeout}ms ease`,
    opacity: 1,
    transform: `translateX(0)`,
  },
  exiting: {
    transition: `all ${timeout}ms ease`,
    opacity: 0,
    transform: `translateX(100px)`,
  },
  exited: {},
  unmounted: {},
}

const Transition: React.FC = props => {
  const { children } = props
  const { originalPath } = React.useContext(I18nextContext)

  return (
    <TransitionGroup>
      <ReactTransition
        key={originalPath}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {status => (
          <div
            style={{
              ...getTransitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  )
}

export default Transition
