import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useTranslation } from "react-i18next"

import classes from "./ScrollToTopButton.module.scss"

const ScrollToTopButton: React.FC = () => {
  const { t } = useTranslation("UI")
  const [show, setShow] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    setShow(window.scrollY > 200 ? true : false)
  }

  const data = useStaticQuery<GatsbyTypes.PageUpButtonQuery>(graphql`
    query PageUpButton {
      PageUpButton: file(relativePath: { eq: "PageUp.png" }) {
        childImageSharp {
          fluid(maxWidth: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.PageUpButton?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return (
    <button
      onClick={() => {
        scrollTo("body")
      }}
      className={
        show ? classes.ScrollToTopButtonActive : classes.ScrollToTopButton
      }
      aria-label={t("Scroll to top")}
    >
      <Img
        className={classes.Img}
        fluid={data.PageUpButton.childImageSharp.fluid}
      />
    </button>
  )
}

export default ScrollToTopButton
