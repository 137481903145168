import React from "react"

import classes from "./NavDropdown.module.scss"

type Props = {
  title: string
}

const NavDropdown: React.FC<Props> = ({ title = "", children }) => {
  return (
    <div className={classes.NavDropdown}>
      <div className={classes.NavDropdownItem}>{title}</div>
      <div className={classes.NavDropdownContainer}>{children}</div>
    </div>
  )
}

export default NavDropdown
