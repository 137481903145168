import React, { useState } from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

import Icon from "../../../images/language.svg"
import classes from "./ChangeLanguageButton.module.scss"

const ChangeLanguageButton: React.FC = () => {
  const [checked, setChecked] = useState(false)
  const { originalPath } = useI18next()

  return (
    <div className={classes.Container}>
      <input
        type="checkbox"
        id="language"
        name="language"
        className={classes.Button}
        checked={checked}
        onChange={() => setChecked(prev => !prev)}
      />
      <label className={classes.Label} htmlFor="language">
        <Icon width={24} height={24} />
        Language
      </label>
      <div className={classes.Content}>
        <Link
          to={originalPath}
          language="ja"
          className={classes.Item}
          onClick={() => setChecked(false)}
        >
          日本語
        </Link>
        <Link
          to={originalPath}
          language="en"
          className={classes.Item}
          onClick={() => setChecked(false)}
        >
          English
        </Link>
      </div>
    </div>
  )
}

export default ChangeLanguageButton
