import React, { useState, useEffect } from "react"
import { PageProps } from "gatsby"

import Navigation from "../molecules/header/Navigation"
import TopLogo from "../atoms/header/TopLogo"
import ChangeLanguageButton from "../atoms/header/ChangeLanguageButton"
import HamburgerIcon from "../atoms/header/HamburgerIcon"
import BackDrop from "../atoms/header/BackDrop"
import classes from "./Header.module.scss"

type Props = {
  location: PageProps["location"]
}

const Header: React.FC<Props> = ({ location }) => {
  const [showNav, setShowNav] = useState(false)

  useEffect(() => {
    setShowNav(false)
  }, [location.pathname])

  useEffect(() => {
    if (showNav) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showNav])

  return (
    <>
      <header className={classes.header}>
        <div className={classes.container}>
          <div className={classes.item}>
            <TopLogo />
          </div>
          <div className={classes.nav}>
            <Navigation showNav={showNav} />
          </div>
          <div className={classes.item}>
            <ChangeLanguageButton />
            <HamburgerIcon showNav={showNav} setShowNav={setShowNav} />
          </div>
        </div>
      </header>
      <BackDrop showNav={showNav} setShowNav={setShowNav} />
    </>
  )
}

export default Header
