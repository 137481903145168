import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import NavDropdown from "../../atoms/header/NavDropdown"
import NavList from "../../atoms/header/NavList"
import NavItem from "../../atoms/header/NavItem"
import classes from "./Navigation.module.scss"

const Navigation: React.FC<{ showNav: boolean }> = ({ showNav }) => {
  const { t } = useTranslation("navigation")
  return (
    <nav className={showNav ? classes.NavigationActive : classes.Navigation}>
      <NavDropdown title={t("Attending")}>
        <NavItem title={t("Registration")} to="/registration" />
        <NavItem title={t("Volunteer")} to="/volunteer" />
      </NavDropdown>
      <NavDropdown title={t("Venue & Hotel")}>
        <NavItem title={t("Travel Information")} to={"/travel"} />
        <NavItem title={t("Floor & Local Map")} to={"/map"} />
        <NavItem title={t("Changing Room")} to={"/changing_room"} />
        <NavItem title={t("Room Reservation")} to={"/hotel"} />
      </NavDropdown>
      <NavDropdown title={t("About")}>
        <NavItem title={t("What is JMoF?")} to="/about" />
        <NavItem title={t("Contact")} to="/contact" />
        <NavList title={t("Policies")}>
          <NavItem title={t("Rules of Conduct")} to="/term/rules_of_conduct" />
          <NavItem
            title={t("Virus Countermeasure")}
            to="/term/virus_countermeasure"
          />
          <NavItem
            title={t("Commercial Transaction Policy")}
            to="/term/commercial_transaction_policy"
          />
          <NavItem title={t("Privacy Policy")} to="/term/privacy_policy" />
        </NavList>
        <NavItem title={t("Statements")} to="/statement" />
      </NavDropdown>
      <NavDropdown title={t("Programs")}>
        <NavItem
          title={t("Event Timetables")}
          to="https://jmof2024.sched.com/"
          isExternal
        />
        <NavList title={t("Special Event")}>
          <NavItem title={t("Charity Raffle")} to={"/special/charity"} />
          <NavItem title={t("Guest of Honor")} to={"/special/guest_of_honor"} />
          <NavItem
            title={t("Animal History Exhibition")}
            to={"/special/exhibition"}
          />
          <NavItem title={t("Dead Dog Party")} to="/special/dead_dog_party" />
        </NavList>
        <NavList title={t("Lounges & Booths")}>
          <NavItem title={t("Welcome Lounge")} to="/lounge/welcome" />
          <NavItem title={t("Artist Lounge")} to="/lounge/artist" />
          <NavItem title={t("VR Lounge")} to="/lounge/vr" />
          <NavItem title={t("Sound Lounge")} to="/lounge/sound" />
          <NavItem title={t("Headless Lounge")} to="/lounge/headless" />
          <NavItem
            title={t("Fursuit Photo Booth")}
            to="https://selfoto.jp/JMoF"
            isExternal
          />
          <NavItem title={t("Official Goods Store")} to="/lounge/store" />
        </NavList>
        <NavList title={t("Results")}>
          <NavItem title={t("2024")} to="/result/2024" />
          <NavItem title={t("2023")} to="/result/2023" />
          <NavItem title={t("2022")} to="/result/2022" />
          <NavItem title={t("2021")} to="/result/2021" />
        </NavList>
      </NavDropdown>
      <NavDropdown title={t("Event Details")}>
        <NavList title={t("Arts & Crafts")} left>
          <NavItem title={t("Dealer's Room")} to="/event/dealers_room" />
          <NavItem title={t("Art Competition")} to="/event/art_competition" />
          {/* <NavItem title={t("Story Contest")} to="/event/story_contest" /> */}
          {/*<NavItem title={t("Fursuit Workshop")} to="/event/fursuit_workshop" />*/}
          <NavItem
            title={t("Fursuit Creator's Competition")}
            to="/event/fursuit_creators_competition"
          />
          <NavItem
            title={t("Photo Competition")}
            to={t("PhotoCompetitionLink")}
            isExternal
          />
        </NavList>
        <NavList title={t("Performances")} left>
          {/* <NavItem title={t("Rock Festival")} to="/event/rock_festival" /> */}
          <NavItem
            title={t("Dance Competition")}
            to="/event/dance_competition"
          />
          {/* <NavItem */}
          {/*   title={t("Performance Stage")} */}
          {/*   to="/event/performance_stage" */}
          {/* /> */}
          {/*<NavItem*/}
          {/*  title={t("Fursuit Movie Voiceover")}*/}
          {/*  to="/event/fursuit_movie_voiceover"*/}
          {/*/>*/}
        </NavList>
        <NavList title={t("Others")} left>
          <NavItem
            title={t("Presentation Session")}
            to="/event/presentation_session"
          />
          {/* <NavItem title={t("Escape Room Game")} to="/event/escape_room_game" /> */}
        </NavList>
        <NavItem title={t("Event Application")} to="/event/application" />
      </NavDropdown>
    </nav>
  )
}
export default Navigation
