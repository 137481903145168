/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { PageProps } from "gatsby"
import SwiperCore, {
  EffectFade,
  EffectCoverflow,
  Autoplay,
  Navigation,
  Pagination,
  A11y,
} from "swiper"
import "swiper/swiper-bundle.min.css"

import Header from "./components/organisms/Header"
import Footer from "./components/organisms/Footer"
import ScrollToTopButton from "./components/atoms/common/ScrollToTopButton"
import Transition from "./utils/Transition"
import CookieConcentBar from "./components/atoms/common/CookieConcentBar"
import "./layout.scss"

SwiperCore.use([
  EffectFade,
  EffectCoverflow,
  Autoplay,
  Navigation,
  Pagination,
  A11y,
])

const Layout: React.FC<PageProps> = ({ children, location }) => {
  return (
    <>
      <Header location={location} />
      <main>
        <Transition>{children}</Transition>
        <ScrollToTopButton />
      </main>
      <Footer />
      <CookieConcentBar />
    </>
  )
}

export default Layout
