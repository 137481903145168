import React from "react"

import classes from "./BackDrop.module.scss"

const BackDrop: React.FC<{
  showNav: boolean
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ showNav, setShowNav }) => {
  return (
    <div
      className={showNav ? classes.BackDropActive : classes.BackDrop}
      onClick={() => setShowNav(prev => !prev)}
    />
  )
}

export default BackDrop
