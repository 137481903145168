import React from "react"
import CookieConsent from "react-cookie-consent"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import classes from "./CookieConcentBar.module.scss"

const CookieConcentBar: React.FC = () => {
  const { t } = useTranslation()
  const { t: navT } = useTranslation("navigation")

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("Got It!")}
      cookieName="cookieConcent"
      style={{
        color: "var(--color-text-black)",
        background: "var(--color-header)",
        textAlign: "center",
        boxShadow: "var(--header-shadow)",
        justifyContent: "center",
        alignItems: "center",
      }}
      buttonStyle={{
        color: "var(--color-text-white)",
        backgroundColor: "var(--color-link)",
        padding: "12px 24px",
        borderRadius: "6px",
        fontSize: "0.8rem",
        transition: "all 0.2s",
      }}
    >
      <p>
        {t(
          "This site uses Cookies to provide and improve the service. By using this site you are agreeing to the use of Cookies."
        )}
      </p>
      <Link className={classes.CookieConcentLink} to="/term/privacy_policy">
        {navT("Privacy Policy")}
      </Link>
    </CookieConsent>
  )
}

export default CookieConcentBar
