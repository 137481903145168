import React, { useState } from "react"

import classes from "./NavList.module.scss"

type Props = {
  title: string
  left?: boolean
}

const NavList: React.FC<Props> = ({ title = "", left, children }) => {
  const [show, setShow] = useState(false)

  const toggleShowHandler = () => {
    setShow(prev => !prev)
  }

  const mouseEnterHandler = () => {
    if (matchMedia("(pointer:fine)").matches) {
      // Device has a mouse
      setShow(true)
    }
  }

  const mouseLeaveHandler = () => {
    if (matchMedia("(pointer:fine)").matches) {
      // Device has a mouse
      setShow(false)
    }
  }

  return (
    <div
      className={show ? classes.NavListActive : classes.NavList}
      onClick={toggleShowHandler}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div
        className={left ? classes.NavListItemLeft : classes.NavListItemRight}
      >
        {title}
      </div>
      <div
        className={`${classes.NavListContainer} ${left ? classes.left : ""}`}
      >
        {children}
      </div>
    </div>
  )
}

export default NavList
