import React, { FC } from "react"

const Lincense: FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <h2>License</h2>
    <p>© 2022-2024 JMoF Executive Committee</p>
  </div>
)

export default Lincense
