import React from "react"
import { useTranslation } from "react-i18next"

import classes from "./HamburgerIcon.module.scss"

const HamburgerIcon: React.FC<{
  showNav: boolean
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ showNav, setShowNav }) => {
  const { t } = useTranslation("UI")
  return (
    <button
      className={classes.HamburgerIconContainer}
      onClick={() => setShowNav(prev => !prev)}
      aria-label={t("Toggle navigation")}
    >
      <div
        className={
          showNav ? classes.HamburgerIconActive : classes.HamburgerIcon
        }
      />
    </button>
  )
}

export default HamburgerIcon
