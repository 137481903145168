import React from "react"
import { Link } from "gatsby-plugin-react-i18next"

import classes from "./NavItem.module.scss"

type Props = {
  title: string
  to: string
  isExternal?: boolean
}

const NavItem: React.FC<Props> = ({ title = "", to = "", isExternal }) => {
  return (
    <>
      {isExternal ? (
        <a
          href={to}
          className={isExternal ? classes.NavItemExternal : classes.NavItem}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      ) : (
        <Link to={to} className={classes.NavItem}>
          {title}
        </Link>
      )}
    </>
  )
}

export default NavItem
