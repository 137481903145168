import { Link } from "gatsby"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import License from "../atoms/footer/License"

import Twitter from "../../images/socialIcons/twitter.svg"
import Flickr from "../../images/socialIcons/flickr.svg"
import Facebook from "../../images/socialIcons/facebook.svg"
import YouTube from "../../images/socialIcons/youtube.svg"

import classes from "./Footer.module.scss"

const Footer: FC = () => {
  const { t } = useTranslation("footer")
  return (
    <footer className={classes.footer}>
      <div className={classes.footerItems}>
        <div>
          <h2>{t("Links")}</h2>
          <ul className={classes.linkList}>
            <li>
              <Link to="/contact">{t("Contact")}</Link>
            </li>
            <li>
              <a
                href="https://vr.jmof.jp/"
                className={classes.external}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("VR JMoF")}
              </a>
            </li>
            <li>
              <a
                href="https://jmof-channel.themedia.jp/"
                className={classes.external}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("JMoF Channel")}
              </a>
            </li>
          </ul>
        </div>
        <License />
      </div>
      <ul className={classes.socialIcons}>
        {[
          {
            component: Facebook,
            alt: "Facebook",
            url: "https://www.facebook.com/JMoF.PR/",
          },
          {
            component: Flickr,
            alt: "Flickr",
            url: "https://www.flickr.com/photos/jmof_archive",
          },
          {
            component: Twitter,
            alt: "Twitter",
            url: t("Twitter URL"),
          },
          {
            component: YouTube,
            alt: "YouTube",
            url: "https://www.youtube.com/c/JMoF_PR",
          },
        ].map(({ component: Icon, alt, url }) => (
          <li key={alt}>
            <a
              href={url}
              rel="noreferrer noopener"
              target="_blank"
              aria-label={alt}
            >
              <Icon className={classes.icon} width="16" height="16" />
            </a>
          </li>
        ))}
      </ul>
      <License className={classes.licenseSp} />
    </footer>
  )
}
export default Footer
