import React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import { useTranslation } from "react-i18next"

import classes from "./TopLogo.module.scss"

const TopLogoImage: React.FC = () => {
  const { t } = useTranslation("UI")
  return <Link to="/" className={classes.TopLogo} aria-label={t("Go home")} />
}

export default TopLogoImage
